/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Se ha propuesto integrar un sistema DRM en HTML5, como si la Web fuera algo capaz de cerrarse a los mandatos de Hollywood. El DRM es una de las peores formas que tienen de controlar qué consumimos, ya lo hacían con el cine, la música, los libros…¿queremos ahora que también pongan puertas a la Web? uno de los pocos lugares de libertad que nos quedan, para intentar hacer frente a esta propuesta, firmad la petición de la FSF pinchando en la siguiente imagen:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.defectivebydesign.org/no-drm-in-html5"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 150px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 109.33333333333333%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAwQB/9oADAMBAAIQAxAAAAGLZ6PTggZw2JqAD//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhIRA//aAAgBAQABBQKYlrEHpKmph5xRSy+mmdZ//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAwEBPwEy7//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBKB/9oACAECAQE/AdLqf//EABkQAAIDAQAAAAAAAAAAAAAAAAABESExEP/aAAgBAQAGPwJaO2UTFGEPm8//xAAcEAADAAIDAQAAAAAAAAAAAAAAARExUSFBkXH/2gAIAQEAAT8hsENrYnPATi8JB6p2bKdnE+HptUTzLn0W16O2T//aAAwDAQACAAMAAAAQ89C//8QAGREBAAIDAAAAAAAAAAAAAAAAAQARECFB/9oACAEDAQE/EAvkX3Wf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQIf/aAAgBAgEBPxBQYBxb/8QAHxAAAgMAAAcAAAAAAAAAAAAAAREAITFBUWFxkdHx/9oACAEBAAE/EC0bC02+C6CbKAjF/UZfefNMLwBEX28RpqwLKVvox0QBQUL2AhgwMhGngGLbH5e5/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Stop the Hollyweb! No DRM in HTML5.\"\n        title=\"Stop the Hollyweb! No DRM in HTML5.\"\n        src=\"/static/9c14b7de21730fcdec78819451e07032/75985/hollywebshare.jpg\"\n        srcset=\"/static/9c14b7de21730fcdec78819451e07032/75985/hollywebshare.jpg 150w\"\n        sizes=\"(max-width: 150px) 100vw, 150px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
